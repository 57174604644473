.termsSelectContainer {
  @apply flex
  flex-col
  max-w-xs;
}

.termsSelectDocLabel {
  @apply text-xl mb-2;
}

.termsSelectDiv {
  @apply w-full
  flex
  flex-row
  items-center;
}

.termsSelectDiv::after {
  @apply bg-chiper-dark-blue
  block
  relative
  right-8;
  content: "";
  width: 11px;
  height: 6px;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.termsSelect {
  @apply w-full
  flex
  flex-row
  items-center
  justify-between
  appearance-none
  border-none
  m-0
  cursor-pointer
  outline-none
  bg-chiper-gray
  py-5
  px-3;
}

.termsSelectOption {
  appearance: none;
  padding: 10px;
}
