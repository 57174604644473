.customersBackground {
  @apply m-auto
  max-w-chiper-1920
  bg-no-repeat;
  background-image: url("../../../assets/images/dots-2.svg");
  background-position-x: left;
  background-position-y: 3rem;
}
.customersTitleCols {
  @apply col-start-4
  col-end-12;
}
.customersCarouselCols {
  @apply col-start-1
  col-end-12;
}
.customersCarouselCols {
  @apply col-start-1 mt-16;
}

.animationStart {
  @apply transition delay-300 duration-700 translate-y-full;
}

.animationEnd {
  @apply translate-y-0;
}
