.sectionCredit{
    @apply
    pt-6
    pb-1
}

.firstGroupCredit{
    @apply
    flex
    gap-x-14
}

.firstGroupCredit h1{
    @apply
    text-8xl
}

.secondGroupCredit{
    @apply
    flex
    gap-x-16
    pt-24
}

.bodyVehicle{
    width: 840px;
    @apply
    pr-12
    pt-24
    font-medium
}

@media screen and (max-width: 1367px) {
    .sectionCredit{
        @apply
        pt-0
        pb-14
    }

    .imgPoints{
        @apply
        w-2/6
        h-40
        pt-8
    }

    .firstGroupCredit h1{
        @apply
        text-7xl
        pt-5
    }

    .bodyVehicle p{
        @apply
        text-xl
    }
}

@media screen and (max-width: 480px) {
    .firstGroupCredit img{
        @apply
        hidden
    }
    .firstGroupCredit h1{
        @apply
        text-6xl
    }
    .secondGroupCredit{
        @apply
        flex-col-reverse
        gap-x-0
        pt-0
    }
    .bodyVehicle{
        @apply
        w-full
        py-10
    }
    .sectionCredit{
        @apply
        py-8
    }
}