.containerUploadFiles{
    @apply
    py-5
    px-12
}

.btnGreen{
    background-color: #00D27C !important;
}

.titleUploadFiles, .bodyUploadFiles{
    @apply
    text-2xl
    pb-7
}

.subtitleUploadFiles{
    @apply
    text-3xl
    font-bold
}

.listUploadFiles, .listUploadFilesCenter{
    @apply
    flex
    pb-8
}

.listUploadFiles img, .listUploadFilesCenter img{
    @apply
    pr-10
    mr-5
}
.test{
    width: 50%
}

.buttonsUploadFiles, .buttonsUploadFiles1{
    @apply
    flex
    justify-evenly
    pt-8
}

.buttonsUploadFiles button{
    width: 18vw;
}

.cardData{
    @apply
    bg-white
    border-2
    rounded-md
    border-chiper-start-gray
    mb-2
    flex
    justify-between
}

.cardTitle{
    @apply
    text-sm
    pt-1.5
}

.cardBody, .cardBody2{
    @apply
    text-base
    font-bold
}

.cardBody2{
    @apply
    pl-4
}

.cardUploadFileTitle{
    @apply
    flex
    flex-row
    items-center
    py-3
}

.cardData img{
    @apply
    px-2
}

.firstTitleUpDoc{
    @apply
    text-base
    font-semibold
    text-center
}

.grayTextUpDoc{
    @apply
    text-sm
    font-extralight
    text-gray-400
}

.divUpDoc, .divUpPDF{
    @apply
    flex
    flex-col
    items-center
    bg-white
    border-dashed
    border-2
    border-chiper-start-gray
    h-52
}

.divUpPDF{
    @apply w-full
}
.containerUpDoc{
    @apply
    my-3 
    flex 
    flex-col 
    items-center
    justify-center
}

.textWarningPDF{
    @apply
    text-sm
    text-cyan-50
    mx-3
    my-2
    font-medium
}
.nameFile{
    @apply
    font-semibold
    text-sm
}

.cardImageTitle{
    @apply
    flex
    flex-row
}

.sideTitleUpDoc{
    @apply
    text-gray-400
    text-xs
    pb-6
}

@media screen and (max-width: 1367px) {
    .imgFoco{
        @apply
        my-8
        mx-8
    }
    .textBodyUploadFiles1{
        @apply
        ml-16
    }

    .buttonsUploadFiles1{
        @apply
        pt-3
    }
}

@media screen and (max-width: 480px) {
    .imgCameraMobile{
        @apply flex justify-center pb-10
    }
    .containerUploadFiles{
        @apply
        bg-white
        py-0
        px-5
    }
    .titleUploadFiles, .subtitleUploadFiles{
        @apply text-base
    }
    .titleUploadFiles{
        @apply pb-0
    }
    .bodyUploadFiles{
        @apply
        text-sm
        pb-5
    }
    .listUploadFiles img, .listUploadFilesCenter img{
        @apply
        pr-0
        mr-5
    }
    .listUploadFiles, .listUploadFilesCenter{
        @apply
        py-5
        flex
        items-center
    }
    .listUploadFilesCenter{
        @apply border-y-2 border-gray-300
        
    }
    .buttonsUploadFiles{
        @apply flex-col-reverse
    }
    .buttonsUploadFiles button{
        @apply w-full mb-4
    }
    .cardBody{
        @apply font-medium text-sm
    }
}