.sectionFAQ{
    @apply py-64;
}

.containerFAQ{
    @apply
    flex
    flex-col
    items-center
}

.containerFAQ h1{
    font-size: 3vw;
    @apply
    pb-7
}

.listFAQ{
    width: 70%;
    @apply
    bg-chiper-gray
    rounded-xl
}

.questionsList{
    @apply
    py-6
    px-9
}

.questionsItems, .questionsItemsCenter, .questionsItems3{
    list-style: none;
    @apply
    flex
    flex-row-reverse
    justify-between
    items-center
    py-3
    w-full
}

.questionsItems3{
    @apply
    border-slate-300
    border-t
    pb-3
}

.questionsItemsCenter{
    @apply 
    border-slate-300
    border-y
    py-3
}
.answerContainer{
    transition: height 4s;
    transition-timing-function: ease-in;
    font-size: 20px;
    @apply
    px-10
    pb-4
}

.answerContainer p{
    @apply
    text-xl
    font-chiper-medium
}

.questionsItems p, .questionsItemsCenter p, .questionsItems3 p{
    font-size: 25px;
    @apply
    font-black
    pl-10
}

.questionsItems img, .questionsItemsCenter img, .questionsItems3 img{
    width: 2vw;
}

@media screen and (max-width: 1367px) {
    .sectionFAQ{
        @apply py-40;
    }
}

@media screen and (max-width: 480px) {
    .sectionFAQ{
        @apply py-20;
    }
    .containerFAQ h1{
        @apply
        pb-5
        text-6xl
    }
    .questionsItems p, .questionsItemsCenter p, .questionsItems3 p{
        @apply
        pl-0
        text-xl
        text-left
    }
    .listFAQ{
        @apply
        w-full
    }
    .containerFAQ{
        @apply
        px-8
    }
    .questionsList{
        @apply
        py-1.5
        px-3
    }
    .questionsItems img, .questionsItemsCenter img, .questionsItems3 img{
        @apply w-16
    }
    
}