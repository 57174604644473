.sectionStepsToApply{
    @apply
    pb-16   
}

.containerStepsToApply{
    @apply
    flex
    flex-col
}
.buttonApply{
    background-color: #00D27C !important;
}

.titleStepsToApply{
    @apply
    flex
    justify-center
}

.titleStepsToApply h1{
    @apply
    font-bold
    text-8xl
}

.bodyStepsToApply{
    @apply
    flex
    flex-row
    justify-evenly
    mt-12
}

.imgStepsToApply{
    @apply
    mt-14
}

.stepByStepsToApply{
    list-style: none;
    @apply
    w-4/12
    flex
    items-center
    flex-col
}

.stepByStepsToApply p{
    text-align: center;
    @apply
    text-xl
}

.stepByStepsToApply button{
    @apply
    w-56
    mt-3
}

.stepApply1, .stepApply{
    font-size: 28px;
        @apply
        font-bold
        text-white
        bg-chiper-green
        rounded-full
        py-1.5
        px-5
        mb-4
        mt-10
}

.stepApply1{
    @apply
    py-2
    px-6
}

@media screen and (max-width: 1441px) {
    .sectionStepsToApply{
        @apply
        pt-12   
    }

    .titleStepsToApply h1{
        @apply
        text-6xl
    }

    .stepByStepsToApply p{
        @apply
        text-base
    }

    .stepByStepsToApply{
        @apply
        w-6/12
        px-24
    }

    .stepApply1, .stepApply{
        font-size: 19px;
            @apply
            py-0.5
            px-3
            mb-4
            mt-10
    }
    
    .stepApply1{
        @apply
        py-0.5
        px-3
    }
    
    .stepByStepsToApply button{
        @apply
        w-40
    }
}

@media screen and (max-width: 480px){
    .bodyStepsToApply{
        @apply
        flex-col-reverse
        items-center
        mt-4
    }
    .titleStepsToApply{
        @apply
        text-center
    }
    .stepByStepsToApply{
        @apply
        w-full
        px-5
    }
    .stepApply1, .stepApply{
        font-size: 28px;
            @apply
            py-1.5
            px-5
    }
    
    .stepApply1{
        @apply
        py-2
        px-6
    }
}