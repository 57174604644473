.notFound404Background {
  @apply bg-no-repeat
  bg-left-bottom
  mt-40
  pb-64;
  background-image: url("../../assets/images/404.png");
  background-size: auto;
}

.notFound404Cols {
  @apply col-start-6
  col-end-12;
}

.notFound404 {
  @apply text-chiper-dark-gray;
  font-size: 26rem;
}

.notFoundTextCols {
  @apply col-start-7
  col-end-12
  mt-20;
}

.notFoundTitle {
  @apply mb-5;
}

.notFoundButton {
  @apply block
  max-w-fit
  bg-chiper-green
  text-white
  mt-10
  p-4
  font-bold
  rounded-lg;
  transition: 0.5s;
  font-size: 1.25rem;
}

.notFoundButton:hover {
  @apply opacity-75;
}
