/* #helped */
.helpedBackground {
  @apply m-auto
  max-w-chiper-1920
  bg-no-repeat;
  background-image: url("../../../assets/images/dots.svg");
  background-position-x: right;
  background-position-y: 6.5rem;
}

.underline{
  border-bottom: 10px solid #FF0136;
  display: inline-block;
  line-height: 1;
}

.helpedTitle {
  @apply text-7xl;
}

.helped {
  @apply bg-chiper-gray;
}
.helpedTitleCols {
  @apply col-start-1
  col-end-8
  mb-24;
}
.helpedTitleUnderline {
  @apply pb-1;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/helped-underline.svg");
}
.helpedTitleUnderlinePT {
  background-position: 0 0.84em;
  background-size: 50rem;
}
.helpedTitleUnderlineES {
  background-position: 0 0.84em;
  background-size: 45rem;
}
.helpedTextCols {
  @apply col-start-1
  col-end-5;
}
.helpedVideoCols {
  @apply col-start-6
  col-end-12;
}
.helpedVideoBox {
  @apply bg-white;
  box-shadow: -4.25142px 24.0914px 21.2571px -17.0057px rgba(0, 0, 0, 0.08);
  border-radius: 8.50284px;
}
.helpedVideoFooter {
  @apply px-8 py-4;
}
.helpedVideoClient {
  @apply text-2xl;
}
/* #helped */

.animationStart {
  @apply transition delay-300 duration-700 translate-y-full;
}

.animationEnd {
  @apply translate-y-0;
}

@media screen and (max-width: 1441px) {
  .helpedTitle {
    font-size: 5.5rem;
  }
  .helpedTitleUnderlineES {
    font-size: 5.5rem;
    background-size: 44rem;
    background-position: 0 0.85em;
  }
}

@media screen and (max-width: 1281px) {
  .helpedTitle {
    @apply text-6xl
  }
  .helpedTitleUnderlineES {
    font-size: 5rem;
    background-size: 40rem;
    background-position: 0 0.9em;
  }
}
