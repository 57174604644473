.sideTitleUpDoc{
  @apply
  text-gray-400
  text-xs
}

.cardImageTitle{
    @apply
    flex
    flex-col
    items-center
    bg-white
    border-dashed
    border-2
    border-chiper-start-gray
    text-center
    h-52
}

.textNewFile{
  @apply
  py-4
}

.containerUpDoc{
  @apply
  my-3 
  flex 
  flex-col 
  items-center
}

.containerButtonClose, .containerButtonCloseWhite{
  @apply
  absolute
  z-10
  w-36
  flex
  flex-row
  justify-end
  mt-3
}

.xClose{
  @apply
  flex
  justify-end
}

.containerButtonCloseWhite{
    margin-top: -20px;
    margin-right: -20px;
}
