.customers {
  @apply pb-9;
  overflow: visible !important;
}

.customersItem {
  @apply bg-chiper-gray
  p-12
  mx-4
  mb-10
  rounded-2xl
  bg-no-repeat
  flex
  flex-col
  justify-between;
  background-position: 2rem 1.5rem;
  background-image: url("../../assets/images/quote.svg");
  height: 540px;
}

.customersItemTitle {
  @apply text-2xl;
}

.customersItemUser {
  @apply flex
  flex-row
}

.customersItemUserName {
  @apply text-xl;
}

.customersItemUserAvatar {
  @apply rounded-full
  mr-4
  mb-3
  h-20
  w-20
}

.customersDataPeople {
  @apply text-2xl
  text-chiper-start-gray;
}

.customersItemFlex {
  @apply flex
  flex-col
  justify-between
  h-full;
}

.startActive {
  @apply text-chiper-start;
}

.textBodyCareers{
  @apply
  text-xl
}

.textCareers{
  @apply
  text-base
  text-black
}

@media screen and (max-width: 1441px) {
  .customersItemUserAvatar {
    @apply
    h-10
    w-10
}
  .textBodyCareers{
    @apply
    text-lg
  }
}

@media screen and (max-width: 1024px) {

  .textBodyCareers{
    @apply
    text-sm
  }
  .customersItemUserName, .textCareers{
    @apply
    text-xs
  }
}

@media screen and (max-width: 480px) {
  .textBodyCareers{
    @apply
    text-lg
  }

  .customersItem{
    @apply
    ml-0
    w-full
  }
}