.containerOurBusiness{
    @apply
    flex
    flex-col
}

.firstGroupBusiness{
    margin-left: 10%;
    @apply
    flex
    flex-col
    w-1/2
    mb-14
}

.secondGroupBusiness{
    column-gap: 60px;
    @apply
    flex
    flex-row
    items-center
    mb-14
}

.firstTitleBusiness{
    @apply
    font-medium
    text-4xl
    mb-4
}

.secondTitleBusiness{
    @apply
    font-bold
    text-8xl
    mb-16
}

.textFirstGroup, .textSecGroup, .textThirdGroup{
    @apply
    text-xl
    font-medium
}

.textSecGroup{
    @apply
    w-1/2
}

.thirdGroupBusiness{
    margin-left: 10%;
    @apply
    w-1/2
}

@media screen and (max-width: 1441px) {
    .ourBusiness{
        @apply
        py-24
    }
    .secondTitleBusiness{
        @apply
        text-6xl
    }
    .firstTitleBusiness{
        @apply
        text-2xl
    }
    .textFirstGroup, .textSecGroup, .textThirdGroup{
        @apply
        text-base
    }
}

@media screen and (max-width: 480px) {
    .ourBusiness{
        @apply
        py-20
    }
    .secondGroupBusiness img {
        @apply
        hidden
    }

    .containerOurBusiness{
        @apply
        px-6
    }

    .firstGroupBusiness, .thirdGroupBusiness{
        @apply
        ml-0
        w-full
    }

    .textSecGroup{
        @apply
        w-full
    }

    .firstGroupBusiness, .secondGroupBusiness{
        @apply
        mb-6
    }
}