  .containerCarouselChiperPeople {
    margin-left: 10%;
    @apply
    mt-36
  }

  .headerChiperPeople{
    @apply
    flex
    flex-row
    mr-0
  }

  .headerChiperPeople h1{
    margin-left: 10%;
    @apply
    text-8xl
    font-bold
  }

  @media screen and (max-width: 1441px) {
    .headerChiperPeople{
        @apply
        mr-0
      }
    
      .headerChiperPeople h1{
        @apply
        text-6xl
        mr-9
      }
      
      .headerChiperPeople img{
        @apply
        h-min
        w-1/2
      }
      .containerCarouselChiperPeople {
        @apply
        mt-20
      }
  }

  @media screen and (max-width: 1024px) {
    .headerChiperPeople img{
      @apply
        w-1/2
    }

    .textBodyCareers{
      @apply
      text-base
    }

    .sectionChiperPeople{
      @apply
      py-28
    }
  }

  @media screen and (max-width: 480px) {
    .sectionChiperPeople{
      @apply
      py-14
    }
    .headerChiperPeople{
      @apply
      text-center
    }
    .headerChiperPeople h1{
      @apply
      mr-0
      px-4
    }
    .headerChiperPeople img{
      @apply
        hidden
    }
    
    .textBodyCareers{
      @apply
      text-lg
    }

    .containerCarouselChiperPeople{
      @apply
      mx-10
    }
  }

  @media screen and (max-width: 400px) {
    .headerChiperPeople h1{
      @apply
      text-5xl
    }
  }