.customers {
    @apply
    pb-9
    pt-28;
  }

.react-multi-carousel-track{
    width: 200px;
}

.containerCardTips{
  @apply
  flex
}

  .customersItem {
      width: 200px;
    @apply bg-white
    p-12
    mr-8
    rounded-2xl
    w-max
    flex
    flex-col
    items-center
    h-72
  }

  .customersItemFlex {
    @apply flex
    flex-col
    justify-between
    h-full;
  }

  .customersDots {
    @apply mt-12;
  }

.containerImgTips{
    position: absolute;
    margin-top: -27%;
}

.containerCard{
    @apply
    flex
    flex-col
    items-center
}

  .containerTextTips{
      @apply
        mt-9
  }

  .textBodyCareers{
    @apply
    text-xl
    text-center
  }

  .textCareers{
    @apply
    text-base
    text-black
    text-center
  }

  @media screen and (max-width: 1024px) {
    .containerImgTips{
      margin-top: -35%;
      @apply
      w-44
    }

    .textCareers{
      @apply
      text-sm
    }
  }

  @media screen and (max-width: 480px) {

    .textCareers{
      @apply
      pt-3
    }

    .customersItem{
      height: 450px;
      @apply
      m-6
    }
  }

  @media screen and (max-width: 320px) {
    .customersItem{
      height: 550px;
      width: 230px;
      @apply
      m-0
    }
  }
