.containerChiperPrinciples{
    @apply
    flex
    flex-col
}

.headerChiperPrinciples{
    column-gap: 60px;
    @apply
    flex
    flex-row
    items-center
    justify-between
}

.headerChiperPrinciples h1{
    margin-left: 6%;
    @apply
    font-bold
    text-8xl
}

.bodyChiperPrinciples{
    @apply
    flex
    flex-wrap
    justify-evenly
    mt-14
}

.cardItem{
    list-style: none;
    column-gap: 30px;
    width: 40%;
    @apply
    flex
    flex-row
    items-baseline
}

.bodyItem{
    @apply
    flex
    flex-col
}

.numberItem,.numberItem1{
    font-size: 28px;
        @apply
        font-bold
        mb-4
        mt-10
        text-white
        bg-chiper-green
        rounded-full
        py-3
        px-6
}

.numberItem1{
    padding-left: 27px;
    padding-right: 27px;
}

.titleItem{
    @apply
    text-3xl
    mb-2.5
}

.textItem{
    @apply
    text-3xl
}

@media screen and (max-width: 1441px) {
    .headerChiperPrinciples h1{
        @apply
        text-6xl
    }
    .headerChiperPrinciples img{
        @apply
        w-1/4
    }

    .numberItem, .numberItem1{
        font-size: 18px;
        @apply
        py-1
        px-3
    }
    .numberItem1{
        padding-left: 14px;
        padding-right: 14px;
    }
    
    .titleItem{
        @apply
        text-xl
        mb-2.5
    }
    
    .textItem{
        @apply
        text-xl
    }
}

@media screen and (max-width: 480px) {
    .sectionChiperPrinciples{
        @apply
        py-20
    }
    .containerChiperPrinciples{
        @apply
        px-6
    }
    .headerChiperPrinciples img{
        @apply
        hidden
    }
    .bodyChiperPrinciples{
        @apply
        flex-col
        mt-16
    }
    .cardItem{
        column-gap: 15px;
        @apply
        w-full
        items-start
        mb-8
    }
    .titleItem{
        @apply
        text-base
        mb-1.5
    }
    
    .textItem{
        @apply
        text-base
    }
    .numberItem, .numberItem1{
            @apply
            mt-0
    }    
}

@media screen and (max-width: 420px) {
    .headerChiperPrinciples h1{
        @apply
        text-5xl
    }
}

@media screen and (max-width: 350px) {
    .headerChiperPrinciples h1{
        font-size: 40px;
    }
}