.sectionChiperOffers{
    @apply
    bg-chiper-gray
    pb-14
}

.containerChiperOffers{
    @apply
    flex
    flex-col
}

.headerChiperOffers{
    @apply
    flex
    justify-center
    mb-36
}

.headerChiperOffers h1{
    @apply
    font-bold
    text-8xl
}

.listItemsOffers{
    @apply
    flex
    flex-wrap
}

.cardItemOffer{
    @apply
    w-1/4
    flex
    flex-col
    items-center
    mb-24
}

.imgItemOffer{
    @apply
    w-max
}

.textItemOffer{
    width: 66%;
    text-align: center;
    @apply
    text-2xl
    font-medium
}

@media screen and (max-width: 1441px) {
    .sectionChiperOffers{
        @apply
        pt-20
        pb-0
    }
    .headerChiperOffers{
        @apply
        mb-20
    }
    .headerChiperOffers h1{
        @apply
        text-6xl
    }
    .textItemOffer{
        @apply
        text-lg
    }
}

@media screen and (max-width: 480px) {
    .sectionChiperOffers{
        @apply
        py-12
    }
    .containerChiperOffers{
        @apply
        px-10
        text-center
    }
    .headerChiperOffers{
        @apply
        mb-12
    }
    .listItemsOffers{
        @apply
        flex-col
    }
    
    .cardItemOffer{
        @apply
        w-full
        items-center
        mb-10
    }
}