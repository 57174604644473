.header {
  @apply px-8
  pt-4
  pb-5
  bg-chiper-bg;
  transition: 0.5s;
}

.buttonMenu{
  @apply
  hidden
}

.headerStick {
  @apply sticky
  z-10
  translate-y-4
  bg-white;
  top: -1rem;
}

.container {
  @apply flex
  flex-row
  justify-between
  content-center
  max-w-chiper-1440
  m-auto;
}

.headerLinksContainer {
  @apply flex
  flex-row
  justify-between;
}

.logo {
  width: 90px;
}

.menu {
  @apply flex
  flex-row
  justify-between
  content-center;
}

.menuItem {
  @apply py-1
  px-3
  ml-8
  text-base;
}

.menuItem:hover {
  @apply border-b-2
  text-chiper
  border-b-chiper;
}

.menuItemActive {
  @apply border-b-2
  font-chiper-bold
  text-chiper
  border-b-chiper;
}

.downloadBtn {
  @apply bg-chiper-green
  text-white
  py-1
  px-4
  ml-8
  translate-x-28
  rounded-lg
  invisible;
  transition: 0.5s;
}

.animateDownloadBtn {
  @apply -translate-x-8 visible delay-200;
}

.animateMenuInitial {
  @apply translate-x-28;
  transition: 0.5s;
}

.animateMenuStart {
  @apply -translate-x-8 delay-200;
}

@media screen and (max-width: 480px) {
  .header {
    @apply
    px-5
    sticky
    bg-chiper-bg;
  }
  .buttonMenu{
    display: inherit;
    @apply
    h-fit
  }
  .headerLinksContainer {
    @apply 
    fixed
    h-full
    justify-between
    bg-white
    right-0
    top-0
  }
  .menu {
    @apply
    flex-col
    bg-white
    justify-between
    content-center;
  }

  .menuMobile{
    box-shadow: -11px 12px 2px 0px rgba(95, 93, 93, 0.5);
    transition: all .5s ease;
    @apply
    pt-20
    pr-16
  }

  .buttonCloseMobile{
    @apply
    p-4
  }

  .menuItem {
    @apply pt-5
    px-1
  }

  .buttonGreen{
    @apply 
    text-white
    py-1
    px-4
    ml-6
    mt-6
    rounded-lg
    bg-chiper-green
  }

  .opacityBkg{
    background-color: rgba(147, 141, 141, 0.5);
  }

  .headerStick {
    transform: none;
    @apply 
    top-0
  }
}