.sectionService{
  @apply
  pb-24
}

.underline{
  border-bottom: 10px solid #FF0136;
  display: inline-block;
  line-height: 1;
}

.containerChiperService{
    @apply
    flex
    justify-between
}

.containerTitleService{
    width: 37%;
    margin-top: -150px;
    @apply
    bg-chiper-gray
    flex
    flex-col
    justify-center
}

.containerTitleService h1{
    padding-left: 30%;
    @apply
    text-8xl
}

.bodyTextService{
    z-index: 3;
    margin-bottom: -100px;
}

.containerTextBodyService{
    width: 560px;
}

.imgBox{
    z-index: 2;
    padding-left: 3%;
    margin-top: 4;
    position: sticky;
}

.imgPointsBox{
    z-index: 1;
    margin-top: -290px;
}

  @media screen and (max-width: 1367px) {
    .containerTitleService h1{
        @apply
        text-6xl
    }    

      .containerTitleService{
        width: 35%;
        margin-top: 0px;
    }
    .containerImgService{
        width: 50%;
        height: auto;
    }

    .containerChiperService{
        height: 100vh;
    }
    .imgBox{
        width: 80%;
    }
    .bodyTextService{
        position: sticky;
        @apply
        text-3xl
    }    
}

@media screen and (max-width: 480px) {
  .sectionService{
    @apply
    px-8
    py-14
  }
  .containerChiperService{
    @apply
    flex-col
    justify-center
  }
  .containerTitleService{
    @apply
    bg-white
    w-full
  }
  .containerTextBodyService{
    @apply
    w-full
    py-6
  }
  .containerTitleService h1{
    @apply
    pl-0
  }
  .containerImgService{
    @apply w-full
  }
  .imgBox{
    position: none;
    @apply
    w-full
    z-0
    pl-0
    mt-0
  }

  .bodyTextService{
    position: none;
    @apply
    text-xl
    mb-0
    z-0
    font-medium
  }
  .imgPointsBox{
    @apply
    hidden
  }

  .underline{
    border-bottom: none;
  }
}