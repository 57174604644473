/* #best-prices */
.bestPricesBackground {
  @apply m-auto
  max-w-chiper-1920
  bg-no-repeat;
  background-image: url("../../../assets/images/dots-3.svg");
  background-position-x: right;
  background-position-y: top;
}

.bestPricesImageCols {
  @apply col-start-1
  col-end-6;
}
.bestPricesTitleCols {
  @apply col-start-7
  col-end-12;
}
.bestPricesTitle {
  @apply mt-12;
  font-size: 5.3rem;
}
.bestPricesSubtitleCols {
  @apply col-start-1
  col-end-12;
}
.bestPricesSubtitle {
  @apply text-center
  mt-20;
}
.bestPricesBrushPT {
  @apply text-white text-center mx-1;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/brush.svg");
  padding: 1rem;
  background-position-y: 12px;
  background-size: 100%;
}
.bestPricesBrushES {
  @apply text-white text-center mx-1;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/brush.svg");
  padding: 1.5rem 0.5rem;
  background-position-y: 30px;
  background-size: 100%;
}
/* #best-prices */

.animationStart {
  @apply transition delay-300 duration-700 translate-y-full;
}

.animationEnd {
  @apply translate-y-0;
}

@media screen and (max-width: 1441px) {
  .bestPricesTitle {
    font-size: 5rem;
    line-height: 5rem;
  }
}

@media screen and (max-width: 1281px) {
  .bestPricesTitle {
    font-size: 4.6rem;
    line-height: 4.6rem;
  }
}
