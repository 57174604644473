/* #need */

.needBackground {
  @apply m-auto
  max-w-chiper-1920
  bg-no-repeat;
  background-image: url("../../../assets/images/dots-2.svg");
  background-position-x: 10%;
  background-position-y: 3.5rem;
}
.needTitleCols {
  @apply col-start-3
  col-end-12
  mb-12;
}
.needTitle {
  font-size: 6rem;
}
.needSubtitleCols {
  @apply col-start-3
  col-end-12
  flex
  flex-row
  justify-between
  items-center;
}
.needText {
  @apply mr-8;
}
.needImage {
  @apply w-full;
}
/* #need */

.animationStart {
  @apply transition delay-300 duration-700 translate-y-full;
}

.animationEnd {
  @apply translate-y-0;
}

@media screen and (max-width: 1441px) {
  .needTitle {
    font-size: 5.5rem;
  }

  .needBackground {
    background-position-x: -10%;
  }
}
