.sectionOurPurpose{
    @apply
    bg-chiper-gray
    pt-0
    pb-20
}

.containerOurPurpose{
    @apply
    flex
    flex-row
}

.imgOurPurpose{
    width: 57%;
}

.textOurPurpose{
    width: 30%;
    @apply
    flex
    items-center
}

.textOurPurpose h1{
    @apply
    font-bold
    text-6xl
}

@media screen and (max-width: 1441px) {
    .textOurPurpose h1{
        @apply
        text-4xl
    }
}

@media screen and (max-width: 480px) {
    .sectionOurPurpose{
        @apply
        py-12
    }

    .imgOurPurpose, .textOurPurpose{
        @apply
        w-full
    }

    .containerOurPurpose{
        @apply
        flex-col-reverse
        mx-4
    }
    .textOurPurpose h1{
        @apply
        text-4xl
        pb-10
    }
}