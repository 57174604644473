[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "EcommerceWeb" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-arrow_up:before {
	content: "\e927";
}
.icon-Coupon:before {
	content: "\e900";
}
.icon-LogoChiper:before {
	content: "\e901";
}
.icon-TicketIcon:before {
	content: "\e902";
}
.icon-info:before {
	content: "\e903";
}
.icon-ChiperLetter:before {
	content: "\e904";
}
.icon-replace:before {
	content: "\e905";
}
.icon-minus:before {
	content: "\e920";
}
.icon-plus:before {
	content: "\e91f";
}
.icon-trash:before {
	content: "\e91e";
}
.icon-filter:before {
	content: "\e91d";
}
.icon-share:before {
	content: "\e91c";
}
.icon-check:before {
	content: "\e91b";
}
.icon-fire:before {
	content: "\e918";
}
.icon-phone-call:before {
	content: "\e919";
}
.icon-whatsapp:before {
	content: "\e91a";
}
.icon-camioncito:before {
	content: "\e906";
}
.icon-alert:before {
	content: "\e907";
}
.icon-clock:before {
	content: "\e908";
}
.icon-Group-45:before {
	content: "\e909";
}
.icon-arrow-down:before {
	content: "\e90a";
}
.icon-Vector:before {
	content: "\e90b";
}
.icon-Information:before {
	content: "\e90c";
}
.icon-settings:before {
	content: "\e90d";
}
.icon-closeSession:before {
	content: "\e90e";
}
.icon-eye:before {
	content: "\e9ce";
}
.icon-play-round:before {
	content: "\ea15";
}
.icon-play:before {
	content: "\ea1c";
}
.icon-Tag:before {
	content: "\e90f";
}
.icon-arrow-left:before {
	content: "\e910";
}
.icon-arrow-right:before {
	content: "\e911";
}
.icon-close:before {
	content: "\e912";
}
.icon-notification:before {
	content: "\e913";
}
.icon-profile:before {
	content: "\e914";
}
.icon-sms:before {
	content: "\e915";
}
.icon-billIcon:before {
	content: "\e916";
}
.icon-email:before {
	content: "\e917";
}
.icon-direction:before {
	content: "\e921";
}
.icon-point:before {
	content: "\e922";
}
.icon-pin:before {
	content: "\e923";
}
.icon-search:before {
	content: "\e924";
}
.icon-download:before {
	content: "\e925";
}
.icon-check_circle:before {
	content: "\e926";
}
