.sectionSteps{
    @apply
    bg-chiper-gray
    pt-0
    pb-28
}

.containerRegistrationSteps{
    @apply
    flex
}

.titleSteps{
    width: 60%;
    @apply
    pt-56
}

.containerTitleRegister{
    @apply
    flex
    flex-row-reverse
}

.titleSteps h1{
    width: 65%;
    @apply
    pb-24
}

.pointsTitle{
    @apply
    w-5/6
}

.containerSteps{
    @apply
    pt-44
}

.steps, .steps1{
    list-style: none;
    @apply
    flex
    items-center
    gap-x-9
    pb-6
}

.steps li, .steps1 li{
    font-size: 28px;
    @apply
    font-bold
    text-white
    bg-chiper-green
    rounded-full
    py-3
    px-6
}

.steps1 li{
    padding-left: 27px;
    padding-right: 27px;
}

.steps p, .steps1 p{
    width: 75%;
    font-size: 30px;
    line-height: 30px;
    @apply
    font-medium
}

.btnStepsRegister{
    background-color: #00D27C !important;
    @apply 
  text-white
  pt-2
  pb-3
  w-32
  mt-10
  rounded-md
  text-lg
  font-bold
}

@media screen and (max-width: 1367px) {
    .sectionSteps{
        @apply
        bg-chiper-gray
        pt-0
        pb-10
    }
    .btnStepsRegister{
        @apply 
      pt-2
      pb-3
      w-28
      text-base
      font-semibold
    }
    .titleSteps{
        @apply
        w-3/4
        pt-36
    }
    .titleSteps h1{
        width: 85%;
        font-size: 70px;
        @apply
        pb-20
    }       
    .containerSteps{
        @apply
        pt-20
    }
    .steps li, .steps1 li{
        font-size: 18px;
        @apply
        py-1
        px-3
    }
    
    .steps1 li{
        padding-left: 14px;
        padding-right: 14px;
    }

    .steps p, .steps1 p{
        font-size: 18px;
        line-height: 25px;
    }
}

@media screen and (max-width: 480px) {
    .pointsTitle{
        @apply hidden
    }
    .containerRegistrationSteps{
        @apply flex-col px-8
    }
    .containerTitleRegister{
        @apply flex-col
    }
    .containerTitleRegister p{
        @apply text-lg
    }
    .btnStepsRegister{
        @apply w-full mt-4
    }
    .sectionSteps{
        @apply
        bg-white
        py-0
    }
    .titleSteps{
        @apply
        py-10
        w-full
    }
    .containerSteps{
        @apply
        pt-0
    }
    .titleSteps h1{
        @apply
        pb-8
        w-full
        text-6xl
    }
}