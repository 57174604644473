.registerFormSection{
    @apply
    py-16
}
.registerFormSection{
    @apply
    flex
    flex-col
    items-center
}

.registerBtn{
    background-color: #00D27C !important;
}

.titleForm{
    @apply
    text-3xl
    font-black
    pb-10
}

.subTitleForm{
    @apply
    text-2xl
    pb-7
}

.registerContainer{
    @apply
    flex
    flex-col
    w-2/4
}

.containerRegisterForm{
    @apply
    bg-chiper-gray
    rounded-xl
}

.containerRegisterForm form, .containerConfirmData{
    @apply
    py-5
    px-12
}

.buttonsForm{
    @apply
    flex
    justify-evenly
    pt-14
}

.buttonsForm button{
    width: 18vw;
}

.bodyTextDriver{
    @apply
    text-lg
    pt-5
}

.bodyTextVehicle{
    @apply
    text-lg
}

.thirdGroupInputForm, .secondGroupInputForm{
    @apply
    mt-3
    w-full
    flex
    justify-between
}

.thirdGroupInputForm{
    @apply
    mb-4
}
.containerInputs{
    width: 49%
}
.vehicleQuestion{
    @apply
    text-xl
    font-black
}

.containerFourthInputs{
    @apply
    flex;
}

.checkboxRound{
    @apply
    pr-5
    flex
    flex-row
    items-center
    gap-x-1.5
    pt-5
  }

.datePickerPopper{
    z-index: 3 !important;
    padding-top: 0px !important;
}

.textAgeNotAllowed{
    margin-top: -10px;
    @apply font-light text-[10px] pl-2 pt-0 text-chiper
}

  /* SECOND STEP */

  .cardData{
      @apply
      bg-white
      border-2
      rounded-md
      border-chiper-start-gray
      mb-2
      flex
      items-center
  }

.cardData img{
    @apply
    px-3
    py-3
}

.cardTitle{
    @apply
    text-sm
    pt-1.5
}

.cardBody{
    @apply
    text-base
    font-bold
}

.containerSteps{
    list-style: none;
    @apply
    flex
    items-center
}
.steps1, .steps{
    font-size: 20px;
    padding: 5px 14px 5px 14px;
    @apply
    font-bold
    text-white
    bg-chiper-green
    rounded-full
}

.disabledSteps{
    font-size: 20px;
    padding: 5px 14px 5px 14px;
    @apply
    font-bold
    text-slate-400
    bg-chiper-dark-gray
    rounded-full
}

.lineSteps{
    width: 80px;
    @apply
    bg-chiper-dark-gray
    mx-1.5
    h-1
  }

.steps1{
    padding: 5px 17px 5px 16px;
}

.containertitelAndSteps{
    @apply
    flex
    justify-between
    items-start
}

.errorOptions {
    color: #ff0136;
    font-size: 12px;
    line-height: 10px;
    margin: 1rem 0;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .registerFormSection{
        @apply
        py-16
    }
    .registerContainer{
        @apply
        w-full
        px-8
    }
    .titleForm{
        @apply
        text-2xl
        pb-8
    }
    .lineSteps{
        width: 20px;
        @apply
        mx-1
        h-0.5
    }
    .steps1, .steps, .disabledSteps{
        font-size: 15px;
        padding: 3px 10px 3px 10px;
    }
    .steps1{
        padding: 3px 11px 3px 11px;
    }
    .subTitleForm{
        @apply
        text-base
        pb-4
    }
    .containerRegisterForm form, .containerConfirmData{
        @apply px-4
    }
    .thirdGroupInputForm, .secondGroupInputForm{
        @apply
        mt-0
        flex-col
    }
    .thirdGroupInputForm input, .secondGroupInputForm input{
        @apply
        mb-3
    }
    .containerInputs{
        @apply w-full
    }
    .vehicleQuestion, .bodyTextVehicle, .bodyTextDriver{
        @apply
        text-sm
    }
    .bodyTextDriver{
        @apply pt-3
    }
    .buttonsForm{
        @apply
        flex
        flex-col-reverse
        pt-8
    }
    .buttonsForm button{
        @apply w-full mt-3
    }
}

@media screen and (max-width: 320px) {
    .titleForm{
        margin-left: -8px;
        @apply
        text-lg
        mr-4
    }
}