.footer {
  @apply bg-chiper-gray py-16;
  display:flex;
  flex-direction: column;
}

.containerFooter {
  display: flex;
  justify-content: space-around;
  padding-bottom: 3.5rem;
}

.footerSocialLinksCols {
  display: flex;
  justify-content: space-around;
}

.footerSocialLinks {
  display: flex;
  padding-left: 3rem;
}

.footerSocialLinks a {
  @apply block;
}

.footerSocialLinks img {
  width: 25px;
  height: 25px;
}

.footerLegalLinksCols {
  display:flex;
  padding-left: 5rem;
}

.footerLegalLinks {
  @apply flex
  flex-row
  items-end
  justify-around;
}

.footerLegalLinks a {
  padding-left: 1rem;
  padding-right: 1rem; 
}

.footerChiperCols {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chiperText {
  @apply text-sm;
}

.socialLink {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

@media screen and (max-width: 480px) {
  .containerFooter {
    @apply
    flex-col
  }
  .footerSocialLinks {
    @apply
    pl-0
  }
  .footerLegalLinksCols {
    @apply
    pl-0
    justify-center
  }
  .footerLegalLinks{
    @apply
    flex-col
    items-center
    mt-10
  }
  .footerLegalLinks a {
    @apply
    mt-4
  }
  .chiperText {
    @apply text-center
  }
}