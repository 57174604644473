.sectionBenefits{
    @apply
    pt-0.5
}

.containerChiperBenefits{
    @apply
    flex
}

.bodyServiceContent{
    @apply
    flex
    flex-col
    w-full
}

.containerImgBenefits{
    @apply
    w-full
}

.pointsImg{
    @apply
    absolute
    z-10
    ml-40
    mt-40
}

.imgRocket{
    @apply
    sticky
    z-20
    ml-48
}

.contentTitleBenefits{
    width: 68%;
    @apply
    flex
    flex-col
    pt-16
}

.contentTitleBenefits p{
    @apply
    pt-10
    leading-8
    font-medium
    text-2xl
}

.btnBenefitsRegister{
    background-color: #00D27C !important;
    @apply 
  text-white
  pt-2
  pb-3
  w-32
  mt-10
  rounded-md
  text-lg
  font-bold
}

@media screen and (max-width: 1367px) {
    .containerImgBenefits{
        @apply
        w-1/2
    }
    .pointsImg{
        @apply
        ml-44
        mt-32
        h-2/3
    }

    .bodyServiceContent{
        width: 560px;
    }

    .contentTitleBenefits h1{
        @apply
        font-medium
        text-6xl
    }
    .contentTitleBenefits p{
        @apply
        leading-5
        text-xl
        font-chiper-semibold
    }
    .btnBenefitsRegister{
        @apply 
        pt-2
        pb-3
        w-28
        text-base
        font-semibold
    }
    .imgRocket{
        @apply
        ml-48
        h-2/3
    }
    .sectionBenefits{
        @apply
        pt-0
        pb-0
    }
}

@media screen and (max-width: 480px) {
    .sectionBenefits{
        @apply
        py-8
    }
    .containerImgBenefits{
        @apply hidden
    }
    .bodyServiceContent{
        @apply
        w-full
        px-8
    }
    .containerChiperBenefits{
        @apply
        flex-col-reverse
    }
    .btnBenefitsRegister{
        @apply
        w-full
    }
    .contentTitleBenefits{
        @apply
        w-full
        pt-0
        pb-8
    }
}