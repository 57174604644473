.containerFinishRegistration{
    @apply
    flex
}

.btnGreen{
    background-color: #00D27C !important;
}

.bodyFinishRegistration{
    @apply
    pt-36
}

.bodyFinishRegistration button{
    @apply
    w-1/2
}

.containerFinishRegistration p{
    @apply
    text-2xl
    pt-6
    pb-56
}

@media screen and (max-width: 480px) {
    .containerFinishRegistration h1{
        @apply text-5xl
    }
    .containerFinishRegistration p{
        @apply
        text-lg
    }
    .bodyFinishRegistration{
        @apply
        pt-16
    }
    .bodyFinishRegistration button{
        width: 240%;
        @apply
        z-20
    }
    .containerFinishRegistration{
        @apply items-start
    }
    .containerFinishRegistration img{
        width: 80%;
        margin-left: -30px;
    }
}

@media screen and (max-width: 400px) {
.bodyFinishRegistration button{
    width: 205%;
}
}