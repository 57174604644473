.Toastify .Toastify__toast-container {
	max-width: 400px;
	top: 16px;
	padding: 0 16px;
	box-sizing: border-box;
	transform: translateX(-50%);
	left: 50%;
	z-index: 99999999;
}

.Toastify .Toastify__toast-container .Toastify__toast {
	background: #313131;
	border-radius: 18px;
	padding: 12px;
	box-sizing: border-box;
	min-height: 56px;
  margin-bottom: 10px;
}
