/* #next */
.nextLevelBackground {
  @apply m-auto
  max-w-chiper-1920
  bg-no-repeat;
  background-image: url("../../../assets/images/dots.svg");
  background-position-x: right;
  background-position-y: top;
}
.nextLevelTitleCols {
  @apply col-start-1
  col-end-9
  mb-12;
}
.nextLevelImageTextCols {
  @apply col-start-1
  col-end-12
  flex
  flex-row
  justify-between
  items-center;
}
.nextLevelText {
  @apply max-w-chiper-550;
}
.nextLevelImage {
  @apply w-full;
}
/* #next */

.animationStart {
  @apply transition
  delay-300
  duration-700
  translate-y-full;
}

.animationEnd {
  @apply translate-y-0;
}
