/* #banner */
.banner {
    @apply bg-chiper-bg pb-0 pt-16;
  }
.underline{
    border-bottom: 10px solid red;
    display: inline-block;
    line-height: 1;
}
  .imgDriver1{
    z-index: 1;
    margin-top: -60px;
    left: 28vh;
    padding-bottom: 3vh;
  }

  .bannerDriverBackground {
    @apply m-auto
    max-w-chiper-1920
    bg-no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }

  .bannerTitleCols {
    @apply col-start-1
    col-end-6
    my-20;
  }

  .containerBanner {
    display: flex;
    justify-content: space-around;
    padding-top: -300px;
  }

  .bannerDriverTitle{
    display: flex;
    flex-direction: column;
    margin-top: 8vh;
    margin-left: 15vh;
  }

  .bannerTitleES {
    font-size: 4.99rem;
    line-height: 6rem;
  }
  .bannerDriverESUnderline {
    @apply pb-1;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/banner-underline.svg");
    background-position: 0 1em;
    background-size: 440px;
  }
  .bannerDriverPTUnderline {
    @apply pb-1;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/banner-underline.svg");
    background-position: 0 0.86em;
    background-size: 480px;
  }

  .bannerDriverSubtitle {
    padding-bottom: 20px
  }

  .bannerDriverTextSubtitle {
    @apply text-xl;
    width: 370px;
    margin-top: 75px;
  }

  /* #banner */

  @media screen and (max-width: 1441px) {

    .bannerDriverTitle{
      margin-top: 4vh;
      margin-left: 1vh;
    }

  .bannerDriverTextSubtitle{
    margin-top: 4vh;
    margin-left: 1vh;
  }

  .imgDriver1{
    padding-bottom: 8vh;
  }
    .bannerDriverESUnderline {
      background-size: 550px;
      background-position: 0 1em;
    }
  }

  @media screen and (max-width: 1367px) {

    .firstTitle{
      font-size: 70px;
    }

    .bannerDriverESUnderline {
      background-size: 370px;
      background-position: 0 1em;
    }
  }

  @media screen and (max-width: 1281px) {
    .bannerDriverBackground {
      background-position-y: 70%;
      background-position-x: 350%;
    }
    .bannerTitleES {
      font-size: 4.3rem;
      line-height: 5rem;
    }
    .bannerTitleESUnderline {
      background-size: 490px;
      background-position: 0 0.95em;
    }
  }


  @media screen and (max-width: 480px) {

    .containerBanner {
      @apply
      flex flex-col pt-0
    }
    .firstTitle{
      @apply
      text-7xl
    }
    .bannerDriverTitle{
      @apply
      ml-0
      mt-0
      px-6
    }
    .imgDriver1{
      @apply
      mt-0
      left-0
      pb-0
      py-8
    }

    .banner {
      @apply py-12;
    }
  }

  @media screen and (max-width: 400px) {
    .bannerDriverESUnderline {
      background-size: 00px;
      background-position: 0 1em;
    }
    .underline{
      border-bottom: 10px solid #FF0136;
      display: inline-block;
      line-height: 1;
    }
    .bannerDriverTextSubtitle{
      @apply text-base;
    }
    .bannerDriverTitle h1{
      font-size: 50px
    }
  }
