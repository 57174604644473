.backgroundBlue{
  border: 2px solid black;
  padding: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  color: aliceblue;
  }



.sectionBanner{
     @apply
    bg-chiper-bg
    pt-20;
}
.headerInterviewTips{
    @apply
    flex
    flex-row
    items-center
    mb-20
}

.headerInterviewTips h1{
    @apply
    text-7xl
    font-bold
    mx-7
    text-center
}

.headerInterviewTips img{
    @apply
    h-min
    w-1/4
}
.containerBanner{
    @apply
    flex
    flex-row
}
.sectionInterviewTips img{
  @apply
      items-center

}

@media screen and (max-width: 1024px) {
    .sectionInterviewTips{
        @apply
        py-20
    }
}

@media screen and (max-width: 480px) {
    .sectionInterviewTips{
        @apply
        py-14
    }
    .headerInterviewTips img{
        @apply
        hidden
    }
}
.bodyBanner{
    row-gap: 50px;
    @apply
    flex
    flex-col
    justify-center
    w-7/12
    pl-64
    pr-6
}

.bodyBanner button{
    @apply
    w-56
}

.titleBanner{
    @apply
    font-bold
    text-8xl
    pt-10
}
.titleBannerb2b{
    @apply
    mb-20
    font-bold
    text-7xl
    text-center
}

.textBanner{
    @apply
    font-bold
    text-5xl
}

.containerImgBanner{
    @apply
    w-6/12
}

@media screen and (max-width: 1441px) {
    .titleBanner{
        @apply
        text-7xl
    }

    .textBanner{
        @apply
        text-4xl
    }

    .bodyBanner{
        @apply
        pl-52
        pr-4
    }
}

@media screen and (max-width: 1367px) {
    .bodyBanner{
        @apply
        pl-24
    }
}

@media screen and (max-width: 1024px) {
    .sectionBanner{
        @apply
        py-6
    }
}

@media screen and (max-width: 480px) {
    .sectionBanner{
        @apply
        py-0
    }

    .bodyBanner button{
        @apply
        hidden
    }

    .containerBanner{
        @apply
        flex
        flex-col
    }
    .containerImgBanner{
        @apply
        w-full
    }
    .bodyBanner{
        row-gap: 0px;
        @apply
        px-3
        w-full
    }

    .titleBanner{
        @apply
        text-6xl
        pb-12
    }
    .textBanner{
        @apply
        text-3xl
    }
}
