.sectionBannerCareers{
    @apply
    bg-chiper-bg
    py-0
}

.buttonApply{
    background-color: #00D27C !important;
    @apply mt-10
}

.containerBannerCareers{
    @apply
    flex
    flex-row
}

.bodyBannerCareers{
    row-gap: 50px;
    @apply
    flex
    flex-col
    justify-center
    w-7/12
    pl-64
    pr-6
}

.bodyBannerCareers button{
    @apply
    w-56
}

.titleBannerCareers{
    @apply
    font-bold
    text-8xl
}

.textBanner{
    @apply
    font-bold
    text-5xl
}

.containerImgBannerCareers{
    @apply
    w-6/12
}

@media screen and (max-width: 1441px) {
    .titleBannerCareers{
        @apply
        text-7xl
    }
    
    .textBanner{
        @apply
        text-4xl
    }
    
    .bodyBannerCareers{
        @apply
        pl-52
        pr-4
    }
}

@media screen and (max-width: 1367px) {
    .bodyBannerCareers{
        @apply
        pl-24
    }
}

@media screen and (max-width: 1024px) {
    .sectionBannerCareers{
        @apply
        py-6
    }
}

@media screen and (max-width: 480px) {
    .sectionBannerCareers{
        @apply
        py-0
    }

    .bodyBannerCareers button{
        @apply
        hidden
    }

    .containerBannerCareers{
        @apply
        flex
        flex-col
    }
    .containerImgBannerCareers{
        @apply
        w-full
    }
    .bodyBannerCareers{
        row-gap: 0px;
        @apply
        px-3
        w-full
    }

    .titleBannerCareers{
        @apply
        text-6xl
        pb-12
    }
    .textBanner{
        @apply
        text-3xl
    }
}