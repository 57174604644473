/* #products */
.productsBackground {
  @apply m-auto
  max-w-chiper-1920
  bg-no-repeat;
  background-image: url("../../../assets/images/dots.svg");
  background-position-x: left;
  background-position-y: bottom;
}
.productsTitleCols {
  @apply col-start-1
  col-end-6;
}

.productsTextCols {
  @apply col-start-7
  col-end-12;
}
.productsText {
  @apply mb-16;
}
/* #products */

.animationStart {
  @apply transition delay-300 duration-700 translate-y-full;
}
.animationEnd {
  @apply translate-y-0;
}

@media screen and (max-width: 1441px) {
  .productsBackground {
    background-position-x: -30%;
  }
}

@media screen and (max-width: 1367px) {
  .productsTitle {
    font-size: 5rem;
    line-height: 5rem;
  }
}

@media screen and (max-width: 1281px) {
  .productsTitle {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  .productsBackground {
    background-position-x: -40%;
  }
}
