.registerText{
    margin-top: -80px;
    @apply
    flex
    flex-col
}

.registerContainer{
    @apply
    flex
    justify-evenly
}

.containerRegisterForm{
    background-color:#F9F9FA;
    width: 550px;
    height: 565px;
    margin-top: -180px;
    @apply
    flex
    rounded-md
}

.registerForm{
    @apply
    flex
    items-center
    justify-center
}

.divRegisterForm{
    @apply
    px-12
    w-auto
    flex
    flex-col
    items-center
}

.divDriverTitle{
    @apply
    w-full
    flex
    justify-start
}

.driverTitle{
    @apply
    font-black
    pb-6
}

.firstDivInputs{
    @apply
    pb-5
    w-full
    flex
    justify-between
}

.firstDivInputs input{
    @apply
    pl-2.5
    w-56
    h-9
    rounded
    border-inherit
    border
}

.secondDivInputs{
    @apply
    pb-5
    w-full
}

.underline{
    border-bottom: 10px solid #FF0136;
    display: inline-block;
    line-height: 1;
}

.secondDivInputs input{
    @apply
    pl-2.5
    w-full
    h-9
    rounded
    border-inherit
    border
}

.secondDivInputs input[type="number"]::-webkit-outer-spin-button,
.secondDivInputs input[type="number"]::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.secondDivInputs input[type="number"] {
-moz-appearance: textfield;
}
.secondDivInputs input[type="number"]:hover,
.secondDivInputs input[type="number"]:focus {
-moz-appearance: number-input;
}

.secondDivInputs input[type=number]::-webkit-inner-spin-button,
.secondDivInputs input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

.bodyFirstTextDriver{
    @apply
    text-xl
    font-semibold
    pb-6
}

.bodyTextDriver{
    @apply
    text-sm
    pb-0
}

.registerTitleUnderline{
    @apply pb-1;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/helped-underline.svg");
}

.registerTitleUnderlinePT {
    background-position: 0 0.84em;
    background-size: 50rem;
  }

.registerTitleUnderlineES {
    background-position: 0 0.84em;
    background-size: 30.5rem;
  }

  .containerCheckboxRound{
      @apply
      flex;
  }

  .checkboxRoundDiv{
    @apply
    pr-5
    pb-6
    flex
    items-start
    flex-row
    gap-x-1.5
  }

  .registerBtn{
    background-color: #00D27C !important;
    @apply
    text-white
    w-full
    pt-3
    pb-4
    rounded-lg
    flex
    justify-center
  }

  @media screen and (max-width: 480px) {
  .registerContainer{
    @apply
    flex
    flex-col
    px-7
  }
  .registerText{
    @apply
    mt-0
  }
  .registerText h1{
      @apply
      text-6xl
  }
  .containerRegisterForm{
    @apply
    w-full
    mt-6
    }   
    .registerForm{
        @apply
        w-full
    }
    .firstDivInputs{
        @apply
        pb-0
        w-full
        flex
        flex-col
    }
    .firstDivInputs input{
        @apply
        w-full
    }
    .firstInputs{
        @apply
        mb-2
    }
    .secondDivInputs{
        @apply
        pb-1.5
    }
    .divDriverTitle{
        @apply
        justify-center
        pb-3
    }
    .driverTitle{
        @apply
        text-3xl
        text-center
    }
    .bodyFirstTextDriver{
        @apply
        text-sm
        pt-6
        pb-2
    }
    .divRegisterForm{
        @apply
        px-4
    }
    .registerSection{
        @apply
        pt-20
        pb-0
    }
    .registerTitleUnderlineES {
        background-position: 0 0.84em;
        background-size: 20rem;
      }
}