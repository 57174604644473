.sectionInterviewTips{
    @apply
    bg-chiper-gray
}

.customersCarouselCols {
    @apply col-start-1
    col-end-12;
  }
  .customersCarouselCols {
    @apply col-start-1 mt-16;
  }

.headerInterviewTips{
    @apply
    flex
    flex-row
    items-center
    mb-20
}

.headerInterviewTips h1{
    @apply
    text-8xl
    font-bold
    mx-7
    text-center
}

.headerInterviewTips img{
    @apply
    h-min
    w-1/4
}

@media screen and (max-width: 1441px) {
    .headerInterviewTips h1{
        @apply
        text-6xl
        mx-2
    }
}

@media screen and (max-width: 1024px) {
    .sectionInterviewTips{
        @apply
        py-20
    }
}

@media screen and (max-width: 480px) {
    .sectionInterviewTips{
        @apply
        py-14
    }
    .headerInterviewTips img{
        @apply
        hidden
    }
}