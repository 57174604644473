/* #banner */
.banner {
  @apply bg-chiper-bg pb-0 pt-16;
}
.underline{
    border-bottom: 10px solid red;
    display: inline-block;
    line-height: 1;
}
.bannerBackground {
  @apply m-auto
  max-w-chiper-1920
  bg-no-repeat;
  background-position-x: right;
  background-position-y: bottom;
}

.bannerPTBackground {
  background-image: url("../../../assets/images/pt/pt-image-1.png");
}

.bannerESBackground {
  background-image: url("../../../assets/images/es/es-image-1.png");
}

.bannerTitleCols {
  @apply col-start-1
  col-end-6
  my-20;
}
.bannerQRCodeCols {
  @apply flex
  flex-row
  justify-between
  items-center
  mt-20;
}
.bannerTitleES {
  font-size: 4.99rem;
  line-height: 6rem;
}
.bannerTitleESUnderline {
  @apply pb-1;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/banner-underline.svg");
  background-position: 0 1em;
  background-size: 565px;
}
.bannerTitlePTUnderline {
  @apply pb-1;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/banner-underline.svg");
  background-position: 0 0.86em;
  background-size: 450px;
}

.bannerQRCodeText {
  @apply ml-6;
  max-width: 350px;
}
.bannerQRCodeTextTitle {
  @apply font-bold text-2xl;
}
.bannerQRCodeTextSubtitle {
  @apply text-2xl;
}
/* #banner */

.animationStart {
  @apply transition
  delay-300
  duration-700
  translate-y-full
  overflow-hidden;
}
.animationEnd {
  @apply translate-y-0;
}

@media screen and (max-width: 1441px) {
  .bannerBackground {
    background-position-x: 200%;
  }
  .bannerTitleES {
    font-size: 4.99rem;
    line-height: 6rem;
  }
  .bannerTitleESUnderline {
    background-size: 550px;
    background-position: 0 1em;
  }
}

@media screen and (max-width: 1367px) {
  .bannerBackground {
    background-position-x: 250%;
  }
  .bannerTitleES {
    font-size: 4.7rem;
    line-height: 6rem;
  }
  .bannerTitleESUnderline {
    background-size: 535px;
    background-position: 0 1em;
  }
}

@media screen and (max-width: 1281px) {
  .bannerBackground {
    background-position-y: 70%;
    background-position-x: 350%;
  }
  .bannerTitleES {
    font-size: 4.3rem;
    line-height: 5rem;
  }
  .bannerTitleESUnderline {
    background-size: 490px;
    background-position: 0 0.95em;
  }
}
